import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import {setProp, deleteProp, arrToObj} from 'bh-mod'
Vue.use(Vuex)

export default {
    state: {
        condo:{},
        floorSettings:false,
        order1floor:false,
        appLoading:false,
        getStarted:false,
        floors:{},
        siteplan:{},
        tags:[],
        currentFloor: {},
        editingLot:{},
        gridStarted:false,
        units:[],
        siteplanSettings:false,
        unitTypes:[],
        unitGroups:[],
        currentShape:{},
        editingFloor:'',
        editorSettings:null,
        floorDrawer:{
            show:false,
            type:'add'
        },
        newAmenityInfo:{},
        editingAmenity:{},
        amenityDrawer:{
            show:false,
            type:'add'
        },
        modal:{
            show:false,
            index:null,
            type:'',
            settings:null,
            title:'',
            identifier:'',
            text:'',
            lot:null,
            confirm:() => {},
            load:false,
            editor:null
        },
        lotDrawer:{
            show:false,
            type:'add'
        },
        newLotInfo:{},
        changeImage:false,
        lots: {},
        currentType:'',
        crumbs:''
    },
    mutations: {
		SET_SETTINGS(state, data) {
			// TODO:
		},
        SET_CRUMBS:(state, data) => {
            state.crumbs = data
        },
        changeType(state,data) {
            state.currentType = data
        },
        UPDATE_FLOOR(state,data) {
            state.floorSettings = false
            console.log('data from store', data)
            let floor = Object.values(state.floors).find(floor => floor.siteplan.id == data.id)
            if (floor != -1) {
                state.floors[floor.id].siteplan = data
            }
        },
        ADD_LOTS: (state,data) => {
            state.currentFloor.siteplan.shapes = data
        },
        CHANGE_CURRENTFLOOR_IMAGE: (state, data) => {
            state.currentFloor.siteplan = data
            state.changeImage = true
        },
        CHANGE_CURRENTFLOOR: (state, data) => {
            let storeFloor = Object.values(state.floors)
            let found = storeFloor.find(floor => {
                return floor.id == data
            })
            state.currentFloor = found
        },
        SET_SITEPLAN: (state, {id,...siteplan}) =>{
            state.siteplan[id] = siteplan
            state.siteplan[id].id = id
        },
        SET_DATA: (state, data) => {
            console.log('APPLYING GRID START TO TRUEEEEEEEEE')
            state.condo.gridStarted = true
        },
        SET_CURRENTFLOOR: (state) => {
            let floors = Object.values(state.floors)
            state.currentFloor = floors[0]
            floors.forEach(floor => {
                if (!floor.siteplan.image) {
                    let id = floor.siteplan.id
                    floor.siteplan.image = state.siteplan[id].image
                    floor.siteplan.height = state.siteplan[id].height
                    floor.siteplan.width = state.siteplan[id].width
                }
            })
        },
        SET_FLOOR_IMAGE: (state,data) => {
            state.floors[data.id] = data
            setProp(state,['floors',data.id],data)
        },
        SITEPLAN_IMAGE_SETTINGS: (state) => {
            console.log('OPENING SITEPLAN SETTINGS')
            state.siteplanSettings = true
        },
        CLOSE_SETTINGS: (state) => {
            state.siteplanSettings = false
        },
        DELETE_SPOT: (state,id) => {
            let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(id))
            state.editorSettings.settings.spots.splice(index,1)
            state.editorSettings.self.redraw()
        },
        SET_EDITOR_SETTINGS: (state,obj) => {
            state.editorSettings = obj
        },
        SHAPE_SELECTED:(state,id) => {
            if (state.currentShape.id !== id) {
                let temp = {
                    id:'',
                    floor:{},
                    lot:{}
                }
                temp.id = String(id)
                state.currentFloor.siteplan.lots.forEach(lot => {
                    if (lot.shapeId == temp.id) {
                        temp.lot = lot
                    }
                })
                temp.floor = state.currentFloor
                if (Object.keys(temp.lot).length !=0 && temp.lot.type == 'info') {
                    state.crumbs = temp.lot.name
                } else if (Object.keys(temp.lot).length != 0 && temp.lot.type != 'info'){
                    let found = {}
                    if(typeof (temp.lot.units[0]) == 'string') {
                        found = Object.values(state.units).find(unit => unit.id == temp.lot.units[0])
                    }else {
                        found = temp.lot.units[0]
                    }
                    state.crumbs = found && found.unitNumber && found.name ? `${found.unitNumber} - ${found.name}` : 'Unit does not exist'
                }
                state.currentType = temp.floor.id
                state.currentShape = temp
            }
        },
        DESELECT_SHAPE:(state) => {
            state.currentShape.id = -1
            state.currentShape.floor = {}
            state.currentShape.lot = {}
        },
        SELECT_LOT_SHAPE: (state,id) => {
            window.editor.selectSpot(id)
            window.editor.redraw()
        },
        CLOSE_MODAL: (state) => {
            state.modal = {
                show:false,
                type:'',
                index:null,
                settings:null,
                title:'',
                identifier:'',
                lot:null,
                text:'',
                editor:null,
                confirm:() => {},
                load:false
            }
        },
        DELETE_LOT: (state,data) => {
           deleteProp(state,['lots',data.id])
        },
        ADD_UPDATE_LOT: (state, {type,data}) => {

            console.log('UPDATING LOT', data)
            if (type === 'delete'){
                let deleteIndex = state.currentFloor.siteplan.shapes.findIndex(x => String(x.id) === String(data.shapeId))
                state.currentFloor.siteplan.shapes.splice(deleteIndex,1)
                let deleteLot = state.currentFloor.siteplan.lots.findIndex(x => String(x.id) === String(data.id))
                state.currentFloor.siteplan.lots.splice(deleteLot, 1)
                let index = state.editorSettings.settings.spots.findIndex(x => String(x.id) === String(data.id))
                state.editorSettings.settings.spots.splice(index,1)
                state.editorSettings.self.redraw()
                return deleteProp(state,['lots',data.id])
            }

            data.siteplan = data.siteplan?.id

            let currentFloorLots = state.floors[state.currentFloor.id].siteplan.lots || []
            console.log('currentfloorlot', currentFloorLots)
            let lotIndex = currentFloorLots.findIndex(x => x.id === data.id)

            console.log('FOUND INDEX', lotIndex)
            if (lotIndex == -1) {
                console.log('ADDING LOT TO SITEPLAN')
                state.floors[state.currentFloor.id].siteplan.lots.push(data)
            }
            else state.floors[state.currentFloor.id].siteplan.lots.splice(lotIndex, 1, data)

            let tags = data.tags || []
            tags.forEach(tag => {
                let found = state.tags.find(x => x.id === tag.id)
                if (!found) {
                    state.tags.push(tag)
                }
            })
        },
        ADD_UPDATE_FLOOR: (state, {type,data}) => {
            if (type === 'delete'){
                if (state.currentFloor === data.id) state.currentFloor = state.order1floor
                return deleteProp(state,['floors',data.id])
            }
            setProp(state,['floors',data.id], data)
        },
        SHOW_MODAL: (state,modal) => {
            state.modal = modal
        },
        SHOW_ADD_FLOOR: (state, data)=> {
            // state.floorDrawer = {
            //     show:true,
            //     type:'add'
            // }
            state.floorSettings = true
        },
        SHOW_ADD_AMENITY: (state, data)=> {
            state.newAmenityInfo = data
            state.amenityDrawer = {
                show:true,
                type:'add'
            }
        },
        SHOW_EDIT_AMENITY: (state, amenity)=> {
            state.editingAmenity = amenity
            state.amenityDrawer = {
                show:true,
                type:'edit'
            }
        },
        CLOSE_ADD_FLOOR: (state,data) => {
            state.floorSettings = false
        },
        SHOW_ADD_LOT: (state, data)=> {
            state.newLotInfo = data
            state.lotDrawer = {
                show:true,
                type:'add'
            }
        },
        SHOW_EDIT_LOT: (state, lot)=> {
            state.editingLot = lot
            state.lotDrawer = {
                show:true,
                type:'edit'
            }
        },
        CLOSE_DRAWER: (state,{type,data}) => {



                state.newLotInfo = null
                state.lotDrawer = {
                    show:false,
                    type:'add'
                }



                state.newAmenityInfo = null
                state.amenityDrawer = {
                    show:false,
                    type:'add'
                }


        },
        CLOSE_FLOOR_DRAWER: (state) => {
            state.editingFloor = ''
            state.floorDrawer = {
                show:false,
                type:'add'
            }

        },
        SET_FLOORS: (state,{floors,...appData}) => {

            state.appData = appData
            let obj = {}
            floors.forEach(x => obj[x.id] = x)
            state.floors = obj

            if (floors.length){
                state.currentFloor = floors[0].id
                state.order1floor = floors[0].id
            }
        },
        SET_APPDATA(state, {tags = [], condo = {}, units = [], gridStarted}) {
            let {floors = [],...data} = condo || {};
            let siteplan = []
            floors.forEach(floor => {
                siteplan.push(floor.siteplan)
            })
            console.log('siteplan', siteplan)
            state.siteplan = arrToObj(siteplan)
            state.condo = data
            state.gridStarted = gridStarted
            state.currentFloor = floors[0]
            state.floors = arrToObj(floors)
            state.units = arrToObj(units)
            state.tags = arrToObj(tags)
            let obj = {}
            // floors.forEach(x => obj[x.id] = x)
            // state.floors = obj

            // if (floors.length){
            //     state.currentFloor = floors[0].id
            //     state.order1floor = floors[0].id
            // }

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
        SET_FLOOR_AND_DATA: (state,floor) => {
            setProp(state,['floors',floor.id], floor)
            state.currentFloor = floor.id
        },
        APP_LOADING: (state, status = null) => state.appLoading = status === null ? !state.appLoading : status,
        EDIT_FLOOR: (state,floor) => {
            state.editingFloor = floor.id
            state.floorDrawer = {
                show:true,
                type:'edit'
            }
        }
    },
    actions: {
        CLOSE_FLOOR_DRAWER: ({commit},{type,data}) => {
            if (type !== 'click') commit('ADD_UPDATE_FLOOR', {type,data})
            commit('CLOSE_FLOOR_DRAWER')
        },
        EDIT_FLOOR: ({state,commit,getters},floor) => {
            commit('EDIT_FLOOR', floor)
        },
        SHOW_MODAL: ({state,commit,getters},{index,settings,editor, type = ''}) => {
            if (type === 'delete'){

                let {id} =  settings.spots[index]
                let lot = Object.values(getters.lots).find(x => x.shapeId == id)

                let modal = {
                    type:'delete',
                    show:true,
                    title:'Deleting Lot',
                    text:'Are you sure you want to delete this Spot?',
                    identifier:'',
                    index,
                    lot,
                    settings,
                    editor
                }
                commit('SHOW_MODAL',modal)
            }
        },
        CHANGE_FLOOR: ({commit,state,rootState}, data) => {
            commit('APP_LOADING')
            $api.get(`/siteplans/${rootState.instance.id}/${data.id}`).then( ({data}) => {
                commit('SET_FLOOR_AND_DATA', data)
                commit('APP_LOADING')
            })
        },
        CONFIRM_MODAL: ({commit, state,rootState}) => {
            let {modal} = state
            if (modal.type === 'delete' && modal.lot && modal.lot.id){

                console.log('DELETING', modal.lot)

                let url = `/lots/${rootState.instance.id}/${state.currentFloor}/${modal.lot.id}`
                $api.delete(url).then( ({data}) => {
                    commit('DELETE_LOT', data)
                    let {settings,editor,index} = modal

                    if (settings.editor.selected_shape == settings.spots[index].id) {
                        editor.deselectSpot();
                    }
                    settings.spots.splice(index, 1);
                    editor.redraw();
                    editor.addAction();
                    commit('CLOSE_MODAL')
                })

            }
        },
        UPDATE_SPOT: ({commit,state, rootState, getters}, data) => {

            if (!data) return
            console.log('SHAPESSSS')

            let found = Object.values(getters.lots).find(x => x.shapeId == data.id)

            console.log('%c UPDATING SPOTTTTTTTT ', 'color:green', found)

            if (!found) return null

            let url = `/siteplans/${rootState.instance.id}/${state.currentFloor.siteplan.id}/${data.id}`
            console.log('URL', url, data)
            $api.put(url, data)
        },
        CLOSE_DRAWER: ({state, commit},{type,data}) => {
            if (type !== 'click') {
                if (type === 'add'){
                    // if (!state.editorSettings.settings.spots) state.editorSettings.settings.spots = []
                    // state.editorSettings.settings.spots.push(state.newAmenityInfo.point)
                }
            commit('ADD_UPDATE_LOT', {type,data})
            }
            commit('CLOSE_DRAWER', data)
        },
        ADD_NEW_LOT: async({ state, commit, dispatch }, data) => {
            commit('ADD_NEW_LOT', data)
        },
        SET_APPDATA: async({ state, commit, dispatch }, data) => {
            commit('SET_APPDATA', data)
        },
    },
    getters: {
        appData: state => state.siteplan, //this.$store.getters['appData']
        currentFloor: state => state.floors[state.currentFloor],
        lots: state => {
            let obj = {}

            let lots = state.floors[state.currentFloor.id].siteplan.lots || []
            lots.forEach(x => {
                obj[x.id] = x
            })
            return obj
        },
        editingFloor: state => state.editingFloor? state.floors[state.editingFloor] : false

    },
}
